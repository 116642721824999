import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/DropdownMenu";
import { Menu, X, ChevronDown } from 'lucide-react';
import { Button } from "../ui/Button";
import Logo from "../../assets/images/logo_white.svg";

interface NavItem {
    title: string;
    href: string;
    dropdownItems?: { title: string; href: string; }[];
}

const navItems: NavItem[] = [
    { title: 'Home', href: '/' },
    {
        title: 'Features',
        href: '/features',
        dropdownItems: [
            { title: 'Analytics', href: '/features/analytics' },
            { title: 'Marketing', href: '/features/marketing' },
            { title: 'Collaboration', href: '/features/collaboration' },
        ]
    },
    { title: 'Pricing', href: '/pricing' },
    { title: 'How it works', href: '/how-it-works' },
    { title: 'Blog', href: '/blogs' },
    { title: 'Contact Us', href: '/contact' }
];

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Get current path to determine active item
    const currentPath = window.location.pathname;
    const dashboardUrl = "https://dashboard.pixsar.io/auth";

    // Function to check if a nav item is active
    const isActive = (href: string) => {
        if (href === '/') {
            return currentPath === href;
        }
        return currentPath.startsWith(href);
    };

    // Function to check if dropdown has active item
    const hasActiveDropdownItem = (dropdownItems?: { href: string }[]) => {
        return dropdownItems?.some(item => isActive(item.href));
    };

    return (
        <motion.header
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full bg-primary text-white fixed top-0 z-50"
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <nav className="flex items-center justify-between h-20">
                    {/* Logo */}
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        className="flex items-center space-x-2"
                    >
                        <img
                            src={Logo}
                            alt="Logo"
                            className="h-40 w-40 rounded-full"
                        />
                    </motion.div>

                    {/* Desktop Navigation */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        className="hidden lg:flex items-center space-x-8"
                    >
                        {navItems.map((item) => (
                            item.dropdownItems ? (
                                <DropdownMenu key={item.title}>
                                    <DropdownMenuTrigger
                                        className={`flex items-center space-x-1 transition-colors relative
                                            ${hasActiveDropdownItem(item.dropdownItems)
                                            ? 'text-white font-medium'
                                            : 'text-gray-300 hover:text-white'}`}
                                    >
                                        <span>{item.title}</span>
                                        <ChevronDown className="h-4 w-4" />
                                        {hasActiveDropdownItem(item.dropdownItems) && (
                                            <motion.div
                                                layoutId="activeIndicator"
                                                className="absolute -bottom-1 left-0 right-0 h-0.5 bg-purple-500"
                                                initial={false}
                                                transition={{ type: "spring", stiffness: 380, damping: 30 }}
                                            />
                                        )}
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="bg-primary border-purple-800">
                                        {item.dropdownItems.map((dropdownItem) => (
                                            <DropdownMenuItem
                                                key={dropdownItem.title}
                                                className={`transition-colors cursor-pointer
                                                    ${isActive(dropdownItem.href)
                                                    ? 'text-white bg-purple-800 font-medium'
                                                    : 'text-gray-300 hover:text-white hover:bg-purple-800'}`}
                                            >
                                                {dropdownItem.title}
                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            ) : (
                                <motion.a
                                    key={item.title}
                                    href={item.href}
                                    className={`relative text-gray-300 hover:text-white transition-colors
                                        ${isActive(item.href) ? 'text-white font-medium' : ''}`}
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {item.title}
                                    {isActive(item.href) && (
                                        <motion.div
                                            layoutId="activeIndicator"
                                            className="absolute -bottom-1 left-0 right-0 h-0.5 bg-purple-500"
                                            initial={false}
                                            transition={{ type: "spring", stiffness: 380, damping: 30 }}
                                        />
                                    )}
                                </motion.a>
                            )
                        ))}
                    </motion.div>

                    {/* Auth Buttons */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        className="hidden lg:flex items-center space-x-4"
                    >
                        <Button variant="ghost" className="text-gray-300 hover:text-white" onClick={() => window.open(dashboardUrl, '_blank')}>
                            Sign in
                        </Button>
                        <Button className="bg-purple-600 hover:bg-purple-700" onClick={() => window.open(dashboardUrl, '_blank')}>
                            Create a B2B Account
                        </Button>
                    </motion.div>

                    {/* Mobile Menu Button */}
                    <motion.button
                        whileTap={{ scale: 0.95 }}
                        className="lg:hidden p-2"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        {isMobileMenuOpen ? (
                            <X className="h-6 w-6" />
                        ) : (
                            <Menu className="h-6 w-6" />
                        )}
                    </motion.button>
                </nav>

                {/* Mobile Menu */}
                <AnimatePresence>
                    {isMobileMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            className="lg:hidden"
                        >
                            <div className="py-4 space-y-4">
                                {navItems.map((item) => (
                                    <div key={item.title} className="px-4">
                                        {item.dropdownItems ? (
                                            <div className="space-y-2">
                                                <div className={`font-medium ${hasActiveDropdownItem(item.dropdownItems) ? 'text-white' : 'text-gray-300'}`}>
                                                    {item.title}
                                                </div>
                                                <div className="pl-4 space-y-2">
                                                    {item.dropdownItems.map((dropdownItem) => (
                                                        <a
                                                            key={dropdownItem.title}
                                                            href={dropdownItem.href}
                                                            className={`block transition-colors
                                                                ${isActive(dropdownItem.href)
                                                                ? 'text-white font-medium'
                                                                : 'text-gray-300 hover:text-white'}`}
                                                        >
                                                            {dropdownItem.title}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <a
                                                href={item.href}
                                                className={`block transition-colors
                                                    ${isActive(item.href)
                                                    ? 'text-white font-medium'
                                                    : 'text-gray-300 hover:text-white'}`}
                                            >
                                                {item.title}
                                            </a>
                                        )}
                                    </div>
                                ))}
                                <div className="px-4 space-y-2">
                                    <Button variant="ghost" className="w-full text-gray-300 hover:text-white" onClick={() => window.open(dashboardUrl, '_blank')}>
                                        Sign in
                                    </Button>
                                    <Button className="w-full bg-purple-600 hover:bg-purple-700" onClick={() => window.open(dashboardUrl, '_blank')}>
                                        Create a B2B Account
                                    </Button>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.header>
    );
};

export default Header;