
/**
 * Function to sanitize and truncate HTML content.
 */
import sanitizeHtml from 'sanitize-html';


/**
 * Utility function for conditional class name management.
 * Filters out falsy values and joins the remaining class names with spaces.
 */
export function cn(...classes: (string | boolean | undefined | null)[]): string {
    return classes.filter(Boolean).join(' ');
}

export const sanitizeAndTruncateHtml = (html: string, maxLength: number = 300): string => {
    // Sanitize the HTML to remove any malicious scripts or unwanted tags
    const sanitizedHtml = sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
        allowedAttributes: {
            a: ['href', 'name', 'target'],
            img: ['src', 'alt'],
        },
    });

    // Create a temporary element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHtml;

    let truncatedHtml = '';
    let charCount = 0;

    // Iterate through child nodes to construct truncated HTML
    function processNodes(nodes: NodeListOf<ChildNode>) {
        for (const node of Array.from(nodes)) {
            if (charCount >= maxLength) break;

            if (node.nodeType === Node.TEXT_NODE) {
                const remainingChars = maxLength - charCount;
                if (node.textContent) {
                    const truncatedText = node.textContent.substring(0, remainingChars);
                    charCount += truncatedText.length;
                    truncatedHtml += truncatedText;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
                // const clone = element.cloneNode(false) as HTMLElement;

                truncatedHtml += `<${element.tagName.toLowerCase()}>`;
                processNodes(element.childNodes);
                truncatedHtml += `</${element.tagName.toLowerCase()}>`;
            }
        }
    }

    processNodes(tempDiv.childNodes);

    // Append ellipsis if content was truncated
    if (charCount >= maxLength) {
        truncatedHtml += '...';
    }

    return truncatedHtml;
};


/**
 * Formats a date string to "DD, MMM, YYYY" format.
 * Example: "2024-12-15T19:35:58.217569Z" -> "15, Dec, 2024"
 */
export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day}, ${month}, ${year}`;
}


export const stripHtmlAndTruncate = (html: string, maxLength: number): string => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    const text = tmp.textContent || tmp.innerText || '';
    return text.length > maxLength ?
        text.slice(0, maxLength).trim() + '...' :
        text;
};
