import React from 'react';
import { motion } from "framer-motion";
import { 
    Mail, 
    Phone, 
    MapPin, 
    Clock, 
    Send,
    Facebook,
    Twitter,
    Instagram,
    Youtube
} from 'lucide-react';
import { Button } from "../ui/Button";

const ContactUs = () => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Add form submission logic here
    };

    const contactInfo = [
        {
            icon: Phone,
            title: "Phone",
            details: "+256 200 912 077",
            link: "tel:+256200912077"
        },
        {
            icon: Mail,
            title: "Email",
            details: "info@pixsar.io",
            link: "mailto:info@pixsar.io"
        },
        {
            icon: MapPin,
            title: "Location",
            details: "MTK Building, Lukuli Rd",
            link: "https://maps.google.com"
        },
        {
            icon: Clock,
            title: "Working Hours",
            details: "Mon - Fri: 9:00 AM - 6:00 PM",
            link: null
        }
    ];

    const socialLinks = [
        { icon: Facebook, href: 'https://www.facebook.com/pixsarmedia', color: 'hover:text-blue-600' },
        { icon: Twitter, href: 'https://x.com/PixsarMedia', color: 'hover:text-blue-400' },
        { icon: Instagram, href: 'https://www.instagram.com/pixsar_media/profilecard', color: 'hover:text-pink-600' },
        { icon: Youtube, href: 'https://youtube.com/@pixsarmedia', color: 'hover:text-red-600' }
    ];

    return (
        <div className="bg-white">
            {/* Hero Section */}
            <div className="relative bg-primary text-white py-20">
                <div className="absolute inset-0 bg-gradient-to-b from-primary/80 to-primary"></div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
                    <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-4xl md:text-5xl font-bold mb-4"
                    >
                        Get in Touch
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1 }}
                        className="text-lg text-gray-100 max-w-2xl mx-auto"
                    >
                        Have questions about Pixsar Media? We're here to help you grow your photography business.
                    </motion.p>
                </div>
            </div>

            {/* Contact Information */}
            <div className="relative">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
                    <div className="grid md:grid-cols-2 gap-16">
                        {/* Contact Form */}
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                            className="space-y-8"
                        >
                            <div>
                                <h2 className="text-3xl font-bold text-gray-900 mb-2">Send us a Message</h2>
                                <p className="text-gray-600">We'll get back to you as soon as possible.</p>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="grid md:grid-cols-2 gap-6">
                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                                            Your Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200
                                                     text-gray-900 placeholder:text-gray-500 focus:outline-none
                                                     focus:ring-2 focus:ring-primary/50 focus:border-transparent
                                                     transition-colors"
                                            placeholder="John Doe"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                                            Your Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200
                                                     text-gray-900 placeholder:text-gray-500 focus:outline-none
                                                     focus:ring-2 focus:ring-primary/50 focus:border-transparent
                                                     transition-colors"
                                            placeholder="john@example.com"
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-2">
                                        Subject
                                    </label>
                                    <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200
                                                 text-gray-900 placeholder:text-gray-500 focus:outline-none
                                                 focus:ring-2 focus:ring-primary/50 focus:border-transparent
                                                 transition-colors"
                                        placeholder="How can we help?"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={6}
                                        className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200
                                                 text-gray-900 placeholder:text-gray-500 focus:outline-none
                                                 focus:ring-2 focus:ring-primary/50 focus:border-transparent
                                                 transition-colors resize-none"
                                        placeholder="Your message..."
                                        required
                                    ></textarea>
                                </div>
                                <Button
                                    type="submit"
                                    className="w-full py-6 bg-primary text-white hover:bg-primary/90 transition-colors"
                                >
                                    Send Message
                                    <Send className="w-4 h-4 ml-2" />
                                </Button>
                            </form>
                        </motion.div>

                        {/* Contact Information */}
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.3 }}
                            className="space-y-8"
                        >
                            <div>
                                <h2 className="text-3xl font-bold text-gray-900 mb-2">Contact Information</h2>
                                <p className="text-gray-600">Reach out to us through any of these channels.</p>
                            </div>

                            <div className="grid gap-6">
                                {contactInfo.map((info, index) => (
                                    <motion.div
                                        key={info.title}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.4 + index * 0.1 }}
                                        className="flex items-start space-x-4"
                                    >
                                        <div className="bg-primary/10 p-3 rounded-lg">
                                            <info.icon className="w-6 h-6 text-primary" />
                                        </div>
                                        <div>
                                            <h3 className="font-semibold text-gray-900">{info.title}</h3>
                                            {info.link ? (
                                                <a
                                                    href={info.link}
                                                    className="text-gray-600 hover:text-primary transition-colors"
                                                >
                                                    {info.details}
                                                </a>
                                            ) : (
                                                <p className="text-gray-600">{info.details}</p>
                                            )}
                                        </div>
                                    </motion.div>
                                ))}
                            </div>

                            {/* Social Links */}
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 mb-4">Follow Us</h3>
                                <div className="flex space-x-4">
                                    {socialLinks.map((social, index) => (
                                        <motion.a
                                            key={index}
                                            href={social.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={`text-gray-400 ${social.color} transition-colors`}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <social.icon className="w-6 h-6" />
                                        </motion.a>
                                    ))}
                                </div>
                            </div>

                            {/* FAQ Link */}
                            {/*<div className="bg-gray-50 p-6 rounded-xl">*/}
                            {/*    <div className="flex items-start space-x-4">*/}
                            {/*        <div className="bg-primary/10 p-3 rounded-lg">*/}
                            {/*            <MessageSquare className="w-6 h-6 text-primary" />*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <h3 className="font-semibold text-gray-900 mb-2">Frequently Asked Questions</h3>*/}
                            {/*            <p className="text-gray-600 mb-4">*/}
                            {/*                Find quick answers to common questions about our services.*/}
                            {/*            </p>*/}
                            {/*            <Button variant="outline" className="border-gray-100 text-gray-900 hover:bg-gray-50">*/}
                            {/*                Visit FAQ*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
