// BlogList.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { sanitizeAndTruncateHtml, formatDate } from '../../lib/utils';

interface Blog {
    id: string;
    title: string;
    content: string;
    created: string;
    image: string;
}

const BlogCard: React.FC<{ blog: Blog }> = ({ blog }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:-translate-y-1">
            {blog.image && (
                <div className={`relative h-48 ${!imageLoaded ? 'animate-pulse bg-gray-200' : ''}`}>
                    <img
                        src={blog.image}
                        alt={blog.title}
                        className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                        onLoad={() => setImageLoaded(true)}
                    />
                </div>
            )}
            <div className="p-6">
                <h2 className="text-2xl font-semibold mb-3 text-gray-900">{blog.title}</h2>
                <div
                    className="mb-4 text-gray-600 line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: sanitizeAndTruncateHtml(blog.content, 200) }}
                />
                <div className="flex items-center justify-between">
                    <time className="text-sm text-gray-500" dateTime={blog.created}>
                        {formatDate(blog.created)}
                    </time>
                    <Link
                        to={`/blogs/${blog.id}`}
                        className="inline-flex items-center text-purple-600 hover:text-purple-800 font-semibold"
                    >
                        Read More
                        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const BlogList: React.FC = () => {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [prevPage, setPrevPage] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async (url: string = 'https://prod.pixsar.io/api/v1/blogs/') => {
        try {
            setLoading(true);
            const response = await fetch(url);
            const data = await response.json();
            setBlogs(data.results);
            setNextPage(data.next);
            setPrevPage(data.previous);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen pt-24 pb-12 bg-gray-50">
            <div className="max-w-6xl mx-auto px-4">
                <h1 className="text-4xl font-bold mb-12 text-center text-gray-900">Latest Blogs</h1>

                {loading ? (
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {[1, 2, 3].map((n) => (
                            <div key={n} className="animate-pulse">
                                <div className="h-48 bg-gray-300 rounded-t-lg" />
                                <div className="p-6 bg-white rounded-b-lg">
                                    <div className="h-6 bg-gray-300 rounded w-3/4 mb-4" />
                                    <div className="h-4 bg-gray-300 rounded w-full mb-2" />
                                    <div className="h-4 bg-gray-300 rounded w-5/6" />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : blogs.length > 0 ? (
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {blogs.map(blog => (
                            <BlogCard key={blog.id} blog={blog} />
                        ))}
                    </div>
                ) : (
                    <p className="text-center text-gray-500">No blogs found.</p>
                )}

                {/* Pagination */}
                {(prevPage || nextPage) && (
                    <div className="flex justify-center gap-4 mt-12">
                        {prevPage && (
                            <button
                                onClick={() => fetchBlogs(prevPage)}
                                className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                            >
                                Previous
                            </button>
                        )}
                        {nextPage && (
                            <button
                                onClick={() => fetchBlogs(nextPage)}
                                className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                            >
                                Next
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogList;