import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import Header from './components/layout/Header';
import Footer from "./components/Footer";
import { SchemaMarkup, SEO } from "./components/SEO";

// Import existing sections for home page
import Hero from './components/hero/Hero';
import SeamlessContentSection from "./components/seamless-content-section/SeamlessContentSection";
import WhyChooseSection from "./components/why-choose-us/WhyChooseUs";

// Import or create other pages
import HowItWorks from './components/how-it-works/HowItWorks'
import ContactUs from './components/contact-us/ContactUs';
import BlogDetail from "./components/blog/BlogDetail";
import BlogList from "./components/blog/BlogList";

// HomePage component to keep existing home page layout
const HomePage: React.FC = () => {
  return (
    <>
      <Hero />
      <SeamlessContentSection />
      <WhyChooseSection />
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <HelmetProvider>
        <SEO 
          title="Pixsar Media, An AI-Powered Photography Platform" 
          description="Transform your photography business with Pixsar Media's AI tools. Automate editing, streamline delivery, and grow your audience."
          keywords="AI photography, live stream, photo editing, photography business, content delivery" 
        />
        <SchemaMarkup />
        
        <div className="min-h-screen bg-[#1a0B2E]">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              {/* Add placeholder routes for other pages */}
              <Route 
                path="/features" 
                element={<div className="pt-20 text-white">Features Page Coming Soon</div>} 
              />
              <Route 
                path="/features/analytics" 
                element={<div className="pt-20 text-white">Analytics Feature Coming Soon</div>} 
              />
              <Route 
                path="/features/marketing" 
                element={<div className="pt-20 text-white">Marketing Feature Coming Soon</div>} 
              />
              <Route 
                path="/features/collaboration" 
                element={<div className="pt-20 text-white">Collaboration Feature Coming Soon</div>} 
              />
              <Route 
                path="/pricing" 
                element={<div className="pt-20 text-white">Pricing Page Coming Soon</div>} 
              />
              <Route 
                path="/contact" 
                element={<ContactUs/>}
              />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/blogs/:id" element={<BlogDetail />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              {/* 404 Route */}
              <Route 
                path="*" 
                element={
                  <div className="pt-20 text-center text-white">
                    <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
                    <p>The page you're looking for doesn't exist.</p>
                  </div>
                } 
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </HelmetProvider>
    </Router>
  );
};

export default App;
