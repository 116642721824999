// BlogDetail.tsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import {Helmet} from "react-helmet-async";
import {stripHtmlAndTruncate} from "../../lib/utils";

interface Blog {
    id: string;
    title: string;
    content: string;
    created: string;
    modified: string;
    image: string;
    image_caption?: string;
}

const BlogDetail = () => {
    const { id } = useParams<{ id: string }>();
    const [blog, setBlog] = useState<Blog | null>(null);
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(`https://prod.pixsar.io/api/v1/blogs/${id}/`);
                const data = await response.json();
                setBlog(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blog:', error);
                setLoading(false);
            }
        };

        fetchBlog();
    }, [id]);

    const sanitizedContent = blog ? DOMPurify.sanitize(blog.content, {
        ALLOWED_TAGS: [
            'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'strong', 'em', 'ul', 'ol', 'li',
            'br', 'hr', 'blockquote', 'span', 'img', 'a', 'i', 'b', 'u', 'sup', 'sub',
            'table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption', 'pre', 'code', 'div'
        ],
        ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style', 'class', 'target', 'rel']
    }) : '';

    if (loading) {
        return (
            <div className="min-h-screen pt-20 bg-gray-50">
                <div className="max-w-5xl mx-auto px-4">
                    <div className="animate-pulse">
                        <div className="h-64 bg-gray-300 rounded-lg mb-6" />
                        <div className="h-10 bg-gray-300 rounded mb-4 w-3/4" />
                        <div className="h-4 bg-gray-300 rounded mb-4" />
                        <div className="h-4 bg-gray-300 rounded mb-4 w-5/6" />
                    </div>
                </div>
            </div>
        );
    }

    if (!blog) {
        return <div className="pt-20 text-center text-gray-500">Blog not found.</div>;
    }

    return (
        <div className="min-h-screen pt-24 pb-12 bg-gray-50">
            <article className="max-w-5xl mx-auto px-4">
                {/* Featured Image Container */}
                {blog && (
                    <Helmet>
                        {/* Basic Meta Tags */}
                        <title>{blog.title} | Pixsar Media</title>
                        <link rel="canonical" href={window.location.href} />

                        <meta name="description" content={stripHtmlAndTruncate(blog.content, 160)} />
                        <meta property="og:description" content={stripHtmlAndTruncate(blog.content, 160)} />
                        <meta name="twitter:description" content={stripHtmlAndTruncate(blog.content, 160)} />

                        {/* Open Graph / Facebook */}
                        <meta property="og:type" content="article" />
                        <meta property="og:site_name" content="Pixsar Media" />
                        <meta property="og:title" content={blog.title} />
                        <meta property="og:image" content={blog.image} />
                        <meta property="og:image:width" content="1200" />
                        <meta property="og:image:height" content="630" />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="article:published_time" content={blog.created} />

                        {/* Twitter */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@pixsarmedia" />
                        <meta name="twitter:title" content={blog.title} />
                        <meta name="twitter:description" content={blog.content.replace(/<[^>]*>/g, '').slice(0, 160)} />
                        <meta name="twitter:image" content={blog.image} />

                        {/* Structured Data */}
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "BlogPosting",
                                "headline": blog.title,
                                "image": blog.image,
                                "datePublished": blog.created,
                                "dateModified": blog.modified || blog.created,
                                "description": stripHtmlAndTruncate(blog.content, 160),
                                "author": {
                                    "@type": "Organization",
                                    "name": "Pixsar Media"
                                },
                                "publisher": {
                                    "@type": "Organization",
                                    "name": "Pixsar Media",
                                    "logo": {
                                        "@type": "ImageObject",
                                        "url": "https://d1bphonyoloacl.cloudfront.net/static/company_logos/logo-white.png"
                                    }
                                },
                                "mainEntityOfPage": {
                                    "@type": "WebPage",
                                    "@id": window.location.href
                                }
                            })}
                        </script>
                    </Helmet>
                )}
                <div className="relative mb-8 bg-white rounded-lg shadow-lg overflow-hidden">
                    {blog.image && (
                        <>
                            <div className={`relative h-96 ${!imageLoaded ? 'animate-pulse bg-gray-200' : ''}`}>
                                <img
                                    src={blog.image}
                                    alt={blog.title}
                                    className={`w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                                    onLoad={() => setImageLoaded(true)}
                                />
                            </div>
                            {blog.image_caption && (
                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 text-sm">
                                    {blog.image_caption}
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* Content Container */}
                <div className="bg-white shadow-lg rounded-lg p-8">
                    <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
                    <div className="flex items-center text-gray-600 mb-8">
                        <time dateTime={blog.created}>
                            {new Date(blog.created).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric'
                            })}
                        </time>
                    </div>

                    <div className="prose prose-lg max-w-none">
                        <div
                            className="wysiwyg-content text-gray-800
                                [&_h4]:text-xl [&_h4]:font-bold [&_h4]:mt-8 [&_h4]:mb-4
                                [&_p]:leading-relaxed [&_p]:mb-6 [&_p]:text-base
                                [&_img]:rounded-lg [&_img]:my-8 [&_img]:mx-auto
                                [&_a]:text-purple-600 hover:[&_a]:text-purple-800"
                            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                        />
                    </div>

                    <div className="mt-12 pt-8 border-t border-gray-200">
                        <Link
                            to="/blogs"
                            className="inline-flex items-center text-purple-600 hover:text-purple-800 font-semibold"
                        >
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                            Back to Blogs
                        </Link>
                    </div>
                </div>
            </article>
        </div>
    );
};

export default BlogDetail;